import React, { useState } from 'react';
import SEO from '@components/SEO';

import GlobalStyle from '@styles/global';

import { LayoutWrapper } from './styles';

import LanguageContext from '@lang/LanguageContext';
import { langBR } from '@lang/langBR';
import { langEN } from '@lang/langEN';

const Layout = ({
  english = false,
  title,
  backgroundColor,
  children,
  ...rest
}) => {
  const [currentLang, setCurrentLang] = useState(langBR);

  const handleLangPortuguese = () => {
    if (currentLang === langEN) {
      setCurrentLang(langBR);
      return currentLang;
    }
    return currentLang;
  };

  const handleLangEnglish = () => {
    if (currentLang === langBR) {
      setCurrentLang(langEN);
      return currentLang;
    }
    return currentLang;
  };

  return (
    <LanguageContext.Provider value={currentLang}>
      <LayoutWrapper
        english={english ? handleLangEnglish() : handleLangPortuguese()}
        {...rest}
      >
        <SEO title={title} />
        {children}
        <GlobalStyle />
      </LayoutWrapper>
    </LanguageContext.Provider>
  );
};

export default Layout;
