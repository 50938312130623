import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const rotate1 = keyframes`
  0% {
      transform: rotateX(45deg) rotateY(45deg) rotateZ(0);
  }

  100% {
      transform: rotateX(45deg) rotateY(45deg) rotateZ(360deg);
  }

`;

const rotate2 = keyframes`
  0% {
      transform: rotateX(60deg) rotateY(0deg) rotateZ(0);
  }

  100% {
      transform: rotateX(60deg) rotateY(0deg) rotateZ(360deg);

  }
`;

const rotate3 = keyframes`
  0% {
      transform: rotateX(45deg) rotateY(-45deg) rotateZ(0);
  }

  100% {
      transform: rotateX(45deg) rotateY(-45deg) rotateZ(360deg);
  }
`;

export const Loader = styled.div`
  height: 200px;
  perspective: 800;
  position: relative;
  transform-style: preserve-3d;
  width: 200px;
`;

export const Arc = styled.div`
  border: solid ${shade(0.23, '#2F3CC1')};
  border-radius: 50%;
  border-width: 10px 0px; /* valor de 10px top e bottom | 0px right e left */
  filter: contrast(2);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &:nth-child(1) {
    animation: ${rotate1} 1.5s -1s linear infinite;
  }

  &:nth-child(2) {
    animation: ${rotate2} 1.5s -0.5s linear infinite;
  }

  &:nth-child(3) {
    animation: ${rotate3} 1.5s 0s linear infinite;
  }
`;
