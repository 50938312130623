import React, { useContext } from "react";
import { StyledFooter, CopyrightText } from "./styles";
import LanguageContext from "@lang/LanguageContext";

const Footer = () => {
  const { contactSection } = useContext(LanguageContext);

  return (
    <StyledFooter>
      <CopyrightText>
        Arkos Digital &mdash; 2020 - 2022 ©{" "}
        {{ contactSection }.contactSection.copyright}
      </CopyrightText>
    </StyledFooter>
  );
};

export default Footer;
