import React, { useContext } from 'react';
import { Container, Wrapper, Title, Subtitle, SliderTitle } from './styles';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import LanguageContext from '@lang/LanguageContext';

const Home = () => {
  const { homeSection } = useContext(LanguageContext);

  const data = useStaticQuery(graphql`
    query MyQuery {
      file(relativePath: { eq: "avatarArkos.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container id="home">
      <Wrapper>
        <Title>{{ homeSection }.homeSection.title}</Title>

        <Subtitle>{{ homeSection }.homeSection.subtitle}</Subtitle>

        <Img fluid={data.file.childImageSharp.fluid} alt="avatarArkos" />
        <SliderTitle>{{ homeSection }.homeSection.partnersTitle}</SliderTitle>
      </Wrapper>
    </Container>
  );
};

export default Home;
