import styled from "styled-components";
import { Container as StyledContainer } from "@components/Container";
import { Row as StyledRow } from "@components/Row";
import { Col as StyledCol } from "@components/Col";

export const Row = styled(StyledRow)`
  justify-content: space-between;
`;

export const Col = styled(StyledCol)``;

export const Container = styled(StyledContainer)`
  display: flex;
  justify-content: center;
  padding: 0px 24px;
  max-width: 100%;
  background: ${(props) => props.theme.primary};
  background: linear-gradient(0deg, #f6f8fc 31%, #112152 0%);

  @media (min-width: 768px) {
    padding: 0px 64px;
  }
`;

export const WrapperBody = styled.div`
  width: 1180px;
  max-width: 100%;
  background: #1c2025;
  padding: 40px;
  border-radius: 2px;

  @media (min-width: 768px) {
    padding: 66px 33px 33px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.background};
  font-family: Orkney;
  padding-bottom: 40px;
  font-size: 32px;
  max-width: 332px;

  @media (min-width: 1200px) {
    padding: 0 0 80px 80px;
    max-width: 500px;
    font-size: 40px;
  }
`;

export const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
    padding: 0 80px;
  }
`;

export const Holder = styled.div`
  color: ${(props) => props.theme.background};
  margin-bottom: 16px;
  padding-right: 16px;
  transition: all 0.35s ease;
  width: 100%;
  min-height: ${(props) => (props.active ? "120px" : "auto")};

  @media (min-width: 1200px) {
    width: ${(props) => (props.active ? "45%" : "27.5%")};
  }
`;

export const ServicesTitle = styled.button`
  font-size: 24px;
  background: transparent;
  position: relative;
  min-height: 30px;
  padding-bottom: 4px;
  margin-bottom: 8px;
  color: ${(props) => props.theme.background};
  font-family: Orkney;
  transition: all 0.35s ease;

  &:before {
    content: "";
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${(props) =>
      props.active ? props.theme.primary : props.theme.holder};
    width: ${(props) => (props.active ? "100%" : "40%")};
    transition: all 0.2s ease-in-out;
  }

  &:hover:before {
    background-color: ${(props) => props.theme.background};
    visibility: visible;
    width: 100%;
  }

  &:focus:before {
    background-color: ${(props) => props.theme.primary};
    visibility: visible;
    width: 100%;
  }

  @media (min-width: 1200px) {
    font-size: 26px;
    text-align: left;
  }
`;

export const ServicesDescription = styled.span`
  font-size: 16px;
  display: ${(props) => (props.active ? "block" : "none")};
  max-width: 100%;
  font-size: 14px;

  @media (min-width: 1200px) {
    min-height: 100px;
  }
`;
