import React from 'react';
import { StyledRow } from './styles';

export const Row = ({ wrap, children, ...rest }) => {
  return (
    <StyledRow {...rest} wrap={wrap}>
      {children}
    </StyledRow>
  );
};
