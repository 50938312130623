/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useContext } from "react";
import Loading from "@components/Loading";
import { Fade } from "react-reveal";
import emailjs from "emailjs-com";
import LanguageContext from "@lang/LanguageContext";
import Input from "@components/Input";
import * as Yup from "yup";
import getValidationErrors from "@utils/getValidationErrors";
import { iconsList } from "./icons";
import {
  Container,
  ContactWrapper,
  Left,
  Right,
  MediumTitle,
  MediumText,
  Link,
  BigText,
  IconUl,
  IconList,
  Text,
  Form,
  Label,
  TagTitle,
  TagList,
  TagUl,
  Tag,
  SubmitButton,
  Row,
  Col,
  BlockCenter,
  SendImage,
  BigTitle,
  ErrorTag,
} from "./styles";

const Contact = () => {
  const formRef = useRef(null);
  const [selectedService, setSelectedService] = useState("");
  const [selectedBudget, setSelectedBudget] = useState("");
  const [loading, setLoading] = useState(false);
  const [send, setSend] = useState(false);
  const [errorService, setErrorService] = useState(false);
  const [errorBudget, setErrorBudget] = useState(false);

  const { contactSection } = useContext(LanguageContext);

  const handleValidateTags = () => {
    if (selectedService === "") {
      setErrorService(true);
    } else {
      setErrorService(false);
    }

    if (selectedBudget === "") {
      setErrorBudget(true);
    } else {
      setErrorBudget(false);
    }
  };

  const handleTagValue = (event, index, type) => {
    event.preventDefault();
    if (type === "service") {
      setSelectedService(
        { contactSection }.contactSection.form.service[index].title
      );
    } else {
      setSelectedBudget(
        { contactSection }.contactSection.form.budget[index].value
      );
    }
  };

  const handleSubmit = async (data, { reset }, event) => {
    event.persist();

    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required(
          { contactSection }.contactSection.form.errors.name
        ),
        email: Yup.string()
          .required({ contactSection }.contactSection.form.errors.email)
          .email({ contactSection }.contactSection.form.errors.invalidEmail),
        phone: Yup.string().required(
          { contactSection }.contactSection.form.errors.phone
        ),
      });

      handleValidateTags();

      await schema.validate(data, { abortEarly: false });

      const formResult = {
        service: selectedService,
        budget: selectedBudget,
        ...data,
      };

      setLoading(true);

      try {
        event.preventDefault();
        emailjs
          .send(
            "service_bhifyim",
            "template_modtsx7",
            formResult,
            "user_WQdidGvtvdrDlEojr5X8P"
          )
          .then(
            (r) => {
              if (r.text === "OK") {
                event.target.reset();
                setSelectedService("");
                setSelectedBudget("");
              }
              setLoading(false);
              setSend(true);
            },
            (error) => {
              alert("Mensagem não enviada. Erro:" + error.text);
            }
          );
      } catch (error) {
        console.error(error);
      }
    } catch (err) {
      const errors = getValidationErrors(err);
      return formRef.current?.setErrors(errors);
    }
  };

  return (
    <Container id="contact">
      <ContactWrapper>
        <Left>
          <BigTitle>
            <Fade bottom delay={100}>
              Arkos Digital
            </Fade>
          </BigTitle>
          <MediumTitle>
            <Fade bottom delay={100}>
              {{ contactSection }.contactSection.contactTitle}
            </Fade>
          </MediumTitle>
          <Text>
            <Fade bottom delay={100}>
              contato@arkos.digital
            </Fade>
          </Text>
          <Link href="https://wa.me/558532280166" aria-label="Phone">
            <Fade bottom delay={100}>
              <MediumText>+55 (85) 3228-0166</MediumText>
            </Fade>
          </Link>
          <Link href="https://wa.me/5585996426187" aria-label="Phone">
            <Fade bottom delay={100}>
              <MediumText>+55 (85) 99642-6187</MediumText>
            </Fade>
          </Link>
          <MediumTitle padding="42px 0 8px 0">
            <Fade bottom delay={100}>
              {{ contactSection }.contactSection.adressSubSection.title}
            </Fade>
          </MediumTitle>
          <Link
            aria-label="Adress"
            adress
            target="_blank"
            rel="noopener noreferrer external"
            href="https://g.page/arkosdigital?share"
          >
            <Fade bottom delay={100}>
              <MediumText>
                <h3>
                  {
                    { contactSection }.contactSection.adressSubSection
                      .adressTitle
                  }
                </h3>

                {{ contactSection }.contactSection.adressSubSection.adress}
                <br />
                {{ contactSection }.contactSection.adressSubSection.adressCep}
              </MediumText>
            </Fade>
          </Link>
          <IconUl>
            {iconsList.map((e, index) => {
              return (
                <IconList>
                  <Link
                    icon
                    aria-label={e.label}
                    target="_blank"
                    rel="noopener noreferrer external"
                    href={e.href}
                    key={index}
                  >
                    <Fade bottom delay={100}>
                      {e.icon}
                    </Fade>
                  </Link>
                </IconList>
              );
            })}
          </IconUl>
        </Left>
        <Right>
          <Fade right>
            <BigText padding="0">
              {loading
                ? { contactSection }.contactSection.form.loading.title
                : send
                ? { contactSection }.contactSection.form.send.title
                : ""}
            </BigText>
          </Fade>
          <Fade right delay={100}>
            <BigText typeTitle padding="0">
              {loading
                ? { contactSection }.contactSection.form.loading.subtitle
                : send
                ? { contactSection }.contactSection.form.send.subtitle
                : { contactSection }.contactSection.form.title}
            </BigText>
          </Fade>
          {loading ? (
            <BlockCenter>
              <Fade>
                <Loading />
              </Fade>
            </BlockCenter>
          ) : send ? (
            <BlockCenter>
              <Fade>
                <SendImage
                  alt="sendImage"
                  src={require("@images/form/send.svg")}
                />
              </Fade>
            </BlockCenter>
          ) : (
            <Form ref={formRef} onSubmit={handleSubmit}>
              <TagTitle>
                <Fade right delay={200}>
                  {{ contactSection }.contactSection.form.interest}
                </Fade>
              </TagTitle>
              {/* <Fade right delay={300}>
                <TagUl>
                  <TagList>
                    <Tag
                      onClick={(e) => handleTagValue(e, 0, "service")}
                      active={
                        selectedService ===
                        { contactSection }.contactSection.form.service[0].title
                          ? true
                          : false
                      }
                    >
                      {{ contactSection }.contactSection.form.service[0].title}
                    </Tag>
                    <Tag
                      onClick={(e) => handleTagValue(e, 1, "service")}
                      active={
                        selectedService ===
                        { contactSection }.contactSection.form.service[1].title
                          ? true
                          : false
                      }
                    >
                      {{ contactSection }.contactSection.form.service[1].title}
                    </Tag>
                  </TagList>
                </TagUl>
              </Fade>
              {errorService ? (
                <ErrorTag>Selecione o serviço desejado</ErrorTag>
              ) : null} */}
              {/* <TagTitle>
                <Fade right delay={400}>
                  {{ contactSection }.contactSection.form.investment}
                </Fade>
              </TagTitle>
              <Fade right delay={500}>
                <TagUl>
                  <TagList>
                    <Tag
                      onClick={(e) => handleTagValue(e, 0, 'budget')}
                      active={
                        selectedBudget ===
                        { contactSection }.contactSection.form.budget[0].value
                          ? true
                          : false
                      }
                    >
                      {{ contactSection }.contactSection.form.budget[0].value}
                    </Tag>
                    <Tag
                      onClick={(e) => handleTagValue(e, 1, 'budget')}
                      active={
                        selectedBudget ===
                        { contactSection }.contactSection.form.budget[1].value
                          ? true
                          : false
                      }
                    >
                      {{ contactSection }.contactSection.form.budget[1].value}
                    </Tag>
                    <Tag
                      onClick={(e) => handleTagValue(e, 2, 'budget')}
                      active={
                        selectedBudget ===
                        { contactSection }.contactSection.form.budget[2].value
                          ? true
                          : false
                      }
                    >
                      {{ contactSection }.contactSection.form.budget[2].value}
                    </Tag>
                    <Tag
                      onClick={(e) => handleTagValue(e, 3, 'budget')}
                      active={
                        selectedBudget ===
                        { contactSection }.contactSection.form.budget[3].value
                          ? true
                          : false
                      }
                    >
                      {{ contactSection }.contactSection.form.budget[3].value}
                    </Tag>
                    <Tag
                      onClick={(e) => handleTagValue(e, 4, 'budget')}
                      active={
                        selectedBudget ===
                        { contactSection }.contactSection.form.budget[4].value
                          ? true
                          : false
                      }
                    >
                      {{ contactSection }.contactSection.form.budget[4].value}
                    </Tag>
                  </TagList>
                </TagUl>
              </Fade>
              {errorBudget ? (
                <ErrorTag>Selecione o investimento desejado</ErrorTag>
              ) : null} */}
              <Row wrap>
                <Col>
                  <Fade right delay={500}>
                    <Label>
                      {{ contactSection }.contactSection.form.namePlaceholder}
                      <Input
                        name="name"
                        placeholder={
                          { contactSection }.contactSection.form.namePlaceholder
                        }
                      />
                    </Label>
                  </Fade>
                </Col>
                <Col>
                  <Fade right delay={600}>
                    <Label>
                      {{ contactSection }.contactSection.form.emailPlaceholder}
                      <Input
                        name="email"
                        type="email"
                        placeholder={
                          { contactSection }.contactSection.form
                            .emailPlaceholder
                        }
                      />
                    </Label>
                  </Fade>
                </Col>
                <Col>
                  <Fade right delay={550}>
                    <Label>
                      {{ contactSection }.contactSection.form.phonePlaceholder}
                      <Input
                        withMask
                        defaultValue="55"
                        mask="+ 99 (99) 99999 9999"
                        name="phone"
                        placeholder={
                          { contactSection }.contactSection.form
                            .phonePlaceholder
                        }
                      />
                    </Label>
                  </Fade>
                </Col>
              </Row>
              <Fade delay={700}>
                <SubmitButton type="submit">
                  {{ contactSection }.contactSection.form.submitButton}
                </SubmitButton>
              </Fade>
            </Form>
          )}
        </Right>
      </ContactWrapper>
    </Container>
  );
};

export default Contact;
