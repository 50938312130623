import React, { useState, useContext, useEffect } from "react";
import LanguageContext from "@lang/LanguageContext";
import { NavWrapper, NavbarHolder, Logo, NavList, Link } from "./styles";
import { Fade } from "react-reveal";

const Navbar = () => {
  const [hovering, setHovering] = useState(false);
  const { navigation } = useContext(LanguageContext);

  useEffect(() => {
    setHovering(true);
    setTimeout(() => {
      setHovering(false);
    }, 10);
  }, []);

  useEffect(() => {
    if (window !== "undefined") {
      window.onscroll = function () {
        scrollPage();
      };
    }
  }, []);

  const scrollPage = () => {
    if (
      document.body.scrollTop > 150 ||
      document.documentElement.scrollTop > 150
    ) {
      document.getElementById("scrollPage").className = "scrollPage";
    } else {
      document.getElementById("scrollPage").className = "scrollPageInitial";
    }
  };

  return (
    <NavWrapper>
      <div id="scrollPage" className="scrollPageInitial">
        <NavbarHolder>
          <Link
            href="#home"
            withoutBottom
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
          >
            <Fade delay={100}>
              <Logo src={require("@images/arkosLogo.svg")} alt="arkosLogo" />
            </Fade>
          </Link>
          {hovering ? (
            <Fade left>
              <Logo fadeLogo src={require("@images/arkos.svg")} alt="arkos" />
            </Fade>
          ) : null}
          <NavList>
            <Link href="#contact">
              <Fade delay={150}>{{ navigation }.navigation.contactUs}</Fade>
            </Link>
          </NavList>
        </NavbarHolder>
      </div>
    </NavWrapper>
  );
};
export default Navbar;
