import React, { useContext } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Fade } from "react-reveal";

import {
  CustomSlider,
  SliderHolder,
  Wrapper,
  CompanyLogo,
  Testimonial,
  Row,
  Col,
  RowProfile,
  Profile,
  Avatar,
  Author,
  AuthorOccupation,
  IconLeft,
} from "./styles";

import LanguageContext from "@lang/LanguageContext";

const Arrow = ({ currentSlide, left, slideCount, children, ...props }) => {
  return left ? (
    <FiChevronLeft {...props} color="#F6F8FC" />
  ) : (
    <FiChevronRight {...props} color="#F6F8FC" />
  );
};

const Slider = () => {
  const { testimonials } = useContext(LanguageContext);
  const settings = {
    autoplay: true,
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <Arrow left />,
    nextArrow: <Arrow />,
  };

  const sliderItems = [
    {
      alt: "urbisLogo",
      companyLogo: "urbisLogo",
      testimonials: { testimonials }.testimonials[0].content,
      avatar: "urbisAvatar",
      author: "Luiz Santos",
      authorOccupation: { testimonials }.testimonials[0].authorOccupation,
    },
  ];

  return (
    <Wrapper>
      <CustomSlider {...settings}>
        {sliderItems.map((item, index) => {
          return (
            <Fade delay={100} key={index}>
              <SliderHolder key={index}>
                <Row>
                  <Col>
                    <IconLeft color="#112152" />
                  </Col>
                  <Col>
                    <CompanyLogo
                      src={require(`@images/testimonials/${item.companyLogo}.svg`)}
                      alt={item.alt}
                    />
                    <IconLeft mobile color="#112152" />
                    <Testimonial>{item.testimonials}</Testimonial>
                    <RowProfile>
                      <Avatar
                        src={require(`@images/testimonials/${item.avatar}.svg`)}
                        alt={item.author}
                      />
                      <Profile>
                        <Author>{item.author}</Author>
                        <AuthorOccupation>
                          {item.authorOccupation}
                        </AuthorOccupation>
                      </Profile>
                    </RowProfile>
                  </Col>
                </Row>
              </SliderHolder>
            </Fade>
          );
        })}
      </CustomSlider>
    </Wrapper>
  );
};
export default Slider;
