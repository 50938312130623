import React, { Fragment } from "react";
import { Loader, Arc } from "./styles";

const Loading = () => {
  return (
    <Fragment>
      <Loader>
        <Arc />
        <Arc />
        <Arc />
      </Loader>
    </Fragment>
  );
};

export default Loading;
