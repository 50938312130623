import styled from 'styled-components';
import Slider from 'react-slick';
import { Col as StyledCol } from '@components/Col';
import { Row as StyledRow } from '@components/Row';

import { ImQuotesLeft } from 'react-icons/im';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.background};
  width: 100%;
  padding: 30px 24px;

  @media (min-width: 768px) {
    padding: 50px 64px;
  }
`;

export const Row = styled(StyledRow)``;

export const Col = styled(StyledCol)`
  align-items: flex-start;

  &:first-child {
    @media (min-width: 768px) {
      padding: 50px 64px 50px 0;
    }
  }
`;

export const CustomSlider = styled(Slider)`
  margin: 24px auto;
  max-width: 1180px;
`;

export const SliderHolder = styled.div`
  max-width: 1180px;
`;

export const CompanyLogo = styled.img`
  padding-bottom: 30px;
`;

export const Testimonial = styled.span`
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  padding-bottom: 15px;

  @media (min-width: 1200px) {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
  }
`;

export const IconLeft = styled(ImQuotesLeft)`
  font-size: 45px;
  display: ${(props) => (props.mobile ? 'block' : 'none')};

  @media (min-width: 768px) {
    font-size: 64px;
    display: ${(props) => (!props.mobile ? 'block' : 'none')};
  }
`;

export const RowProfile = styled(Row)`
  align-items: center;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
`;

export const Avatar = styled.img``;

export const Author = styled.span`
  font-weight: 700;
  font-size: 24px;
  font-family: Orkney;
  color: ${(props) => props.theme.primary};
`;

export const AuthorOccupation = styled.span`
  font-size: 16px;
  color: ${(props) => props.theme.primary};
`;
