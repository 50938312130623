export const langBR = {
  navigation: {
    joinUs: "Trabalhe conosco",
    blog: "Blog",
    contactUs: "Entre em contato",
  },

  homeSection: {
    title: "Desenvolvimento de produtos digitais personalizados.",
    subtitle:
      "Somos especialistas em agregar valor para a sua empresa por meio de soluções que alinham pessoas, processos e sistemas.",
    partnersTitle: "Nossos parceiros",
  },

  servicesSection: {
    title: "O que entregamos com excelência:",
    services: [
      {
        title: "Design & Marketing",
        description:
          "Sem enrolação. Combinamos as melhores metodologias de design com sua marca, para entregar soluções que impulsionam seu negócio. Com uma boa gestão e consultoria de marketing otimizamos os resultados da sua empresa!",
      },
      {
        title: "Web & Aplicativos",
        description:
          "Na Arkos criamos aplicativos que fazem toda a diferença! Desktop, Android ou iOS, nosso objetivo é trazer valor para o seu negócio sob a ótica da customização, design e excelência da experiência ao usuário. Oferecemos: site institucional, one page, blog, design e desenvolvimento de landing page e criação web em geral.",
      },
      {
        title: "Automatização",
        description:
          "Elimine boa parte dos processos manuais na sua empresa! Softwares que automatizam atividades repetitivas podem te economizar tempo e dinheiro, além de permitir que funcionários possam executar atividades de maior valor. Oferecemos RPA (Robotic Process Automation), Chatbot, middleware e entre outros.",
      },
      {
        title: "E-commerce",
        description:
          "Não há fronteiras para quem possui loja virtual! Disponibilidade para vender 24h por dia sem se limitar a lojas físicas, alto controle de estoque, custo relativamente baixo de manutenção e desenvolvimento. Na Arkos possuímos as melhores soluções para a construção, design e integração com outras plataformas ou turbinamento do seu e-commerce. Além da máxima personalização de acordo com: sistema de entregas, múltiplos meios de pagamento, dashboards de controle integrados.",
      },
      {
        title: "Bank as a Service",
        description:
          "Já pensou na possibilidade de gerenciar sua própria solução financeira de forma simples e segura? Com a nossa plataforma, transforme sua empresa em uma prestadora de serviços de banking com produtos inovadores e sob medida para as necessidades de seu público com a nossa plataforma white label. Estamos prontos para trabalhar com qualquer tipo de segmento e modelo de negócio, desde uma startup a grandes varejistas.",
      },
      {
        title: "Squad as a Service",
        description:
          "Receba nossos profissionais de front-end, back-end, design e infraestrutura em sua empresa como parte de sua equipe.",
      },
    ],
  },

  testimonials: [
    {
      authorOccupation: "CEO da Urbis",
      content:
        "A equipe da Arkos entendeu as necessidades do meu negócio, propôs soluções assertivas e executou todas com muito profissionalismo. Com eles, estamos mais seguros na tecnologia e podemos evoluir o negócio, a Arkos comprou a ideia da Urbis junto comigo.",
    },
  ],

  contactSection: {
    contactTitle: "Contatos",
    adressSubSection: {
      title: "Onde estamos",
      adressTitle: "Brasil, Fortaleza - CE",
      adress: "Etevaldo Nogueira Business",
      adressCep: "Av. Dom Luis, 870, 20º andar - 60160-230",
      adressTwoTitle: "Brasil, São Paulo - SP",
      adressTwo: "You Metropolitan Tatuape",
      adressTwoCep: "Rua Catiguá, 159, 19º andar - 03065-030",
    },
    form: {
      title: "Vamos conversar?",
      interest: "",
      service: [
        {
          title: "Desenvolvimento",
        },
        {
          title: "Outsourcing",
        },
      ],
      investment: "Estou disposto(a) a investir",
      budget: [
        {
          value: "menos do que R$ 5.000",
        },
        {
          value: "entre R$ 5.000 e R$ 10.000",
        },
        {
          value: "entre R$ 10.000 e R$ 20.000",
        },
        {
          value: "mais do que R$ 20.000",
        },
        {
          value: "Ainda não sei",
        },
      ],
      namePlaceholder: "Nome",
      emailPlaceholder: "E-mail",
      phonePlaceholder: "Celular",
      submitButton: "Enviar",
      errors: {
        service: "Informe qual serviço deseja",
        budget: "Informe seu orçamento",
        name: "Seu nome é obrigatório",
        email: "Seu e-mail é obrigatório",
        invalidEmail: "E-mail inválido!",
        phone: "Seu telefone é obrigatório",
        generalError: "O formulário possui erros, corrija-os para continuar",
      },
      loading: {
        title: "Enviando para a nossa equipe",
        subtitle: "Por favor, aguarde",
      },
      send: {
        title: "Em breve entraremos em contato",
        subtitle: "A equipe Arkos agradece!",
      },
    },
    copyright: "Todos os direitos reservados",
  },
};
