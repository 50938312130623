import styled from 'styled-components';
import { transparentize } from 'polished';
import ReactInputMask from 'react-input-mask';

export const StyledInputMask = styled(ReactInputMask)`
  padding: 15px 15px 6px;
  padding-left: 0;
  display: block;
  width: 202px;
  border-radius: 0;
  font-size: 16px;
  background-color: transparent;
  border-bottom: 1px solid ${(props) => transparentize(0.8, props.theme.text)};
  color: ${(props) => props.theme.text};
  letter-spacing: 1.2px;
  margin-bottom: 16px;

  &:focus {
    border-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.primary};

    &::-webkit-input-placeholder {
      color: ${(props) => transparentize(0.8, props.theme.primary)};
    }
  }

  &::-webkit-input-placeholder {
    color: ${(props) => transparentize(0.8, props.theme.text)};
  }

  @media screen and (max-width: 902px) {
    width: 100%;
  }
`;

export const StyledInput = styled.input`
  padding: 15px 15px 6px;
  padding-left: 0;
  display: block;
  width: 202px;
  border-radius: 0;
  font-size: 16px;
  background-color: transparent;
  border-bottom: 1px solid ${(props) => transparentize(0.8, props.theme.text)};
  color: ${(props) => props.theme.text};
  letter-spacing: 1.2px;
  margin-bottom: 16px;

  &:focus {
    border-color: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.primary};

    &::-webkit-input-placeholder {
      color: ${(props) => transparentize(0.8, props.theme.primary)};
    }
  }

  &::-webkit-input-placeholder {
    color: ${(props) => transparentize(0.8, props.theme.text)};
  }

  @media screen and (max-width: 902px) {
    width: 100%;
  }
`;
