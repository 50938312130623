import styled from 'styled-components';

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 24px;
  background: ${(props) => props.theme.text};
`;

export const CopyrightText = styled.span`
  color: ${(props) => props.theme.background};
`;
