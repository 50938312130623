import styled from 'styled-components';

export const StyledAlert = styled.aside`
  z-index: 5;
  width: 100%;
  background: ${(props) => props.theme.text};
  color: ${(props) => props.theme.background};
  font-size: 14px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 24px;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 1180px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Message = styled.span``;

export const Strong = styled.span`
  font-weight: bold;
`;

export const LinkToEnglish = styled.a`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 16px;
  color: ${(props) => props.theme.background};
  background: ${(props) => props.theme.primary};
  cursor: pointer;
  margin: 0 24px;
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
`;
