export const sliderItems = [
  {
    alt: "to-garantido",
    image: "togarantidoLogo",
  },
  {
    alt: "arquiba",
    image: "arquibaLogo",
  },
  {
    alt: "btg-pactual",
    image: "btgPactualLogo",
  },
  {
    alt: "decode",
    image: "decodeLogo",
  },
  {
    alt: "urbis",
    image: "urbisLogo",
  },
  {
    alt: "nb-consulting",
    image: "nbLogo",
  },
  {
    alt: "lg-auto",
    image: "lgLogo",
  },
  {
    alt: "flyFast",
    image: "flyfastLogo",
  },
  {
    alt: "gocase",
    image: "gocaseLogo",
  },
  {
    alt: "ferreri",
    image: "ferreriLogo",
  },
  {
    alt: "c3c",
    image: "c3cLogo",
  },
  {
    alt: "aplicativei",
    image: "aplicativeiLogo",
  },
  {
    alt: "barato",
    image: "baratoLogo",
  },
  {
    alt: "appqd",
    image: "appqdLogo",
  },
  {
    alt: "bewallet",
    image: "bewalletLogo",
  },
  {
    alt: "dr.on",
    image: "dr.onLogo",
  },
  {
    alt: "galapagos",
    image: "galapagosLogo",
  },
  {
    alt: "portalsabores",
    image: "portalsaboresLogo",
  },
  {
    alt: "zanchetta",
    image: "zanchettaLogo",
  },
  {
    alt: "privalia",
    image: "privaliaLogo",
  },
];
