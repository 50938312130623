import React, { useState, useContext } from "react";
import {
  Container,
  WrapperBody,
  Title,
  ServicesWrapper,
  Holder,
  ServicesTitle,
  ServicesDescription,
} from "./styles";
import { Fade } from "react-reveal";
import LanguageContext from "@lang/LanguageContext";

const OurServices = () => {
  const { servicesSection } = useContext(LanguageContext);

  const services = [
    {
      ref: "webAndDesktop",
      title: { servicesSection }.servicesSection.services[0].title,
      description: { servicesSection }.servicesSection.services[0].description,
    },
    {
      ref: "apps",
      title: { servicesSection }.servicesSection.services[1].title,
      description: { servicesSection }.servicesSection.services[1].description,
    },
    {
      ref: "outsourcing",
      title: { servicesSection }.servicesSection.services[2].title,
      description: { servicesSection }.servicesSection.services[2].description,
    },
    {
      ref: "ecommerce",
      title: { servicesSection }.servicesSection.services[3].title,
      description: { servicesSection }.servicesSection.services[3].description,
    },
    {
      ref: "bankservice",
      title: { servicesSection }.servicesSection.services[4].title,
      description: { servicesSection }.servicesSection.services[4].description,
    },
    {
      ref: "squadservice",
      title: { servicesSection }.servicesSection.services[5].title,
      description: { servicesSection }.servicesSection.services[5].description,
    },
  ];

  const [currentService, setCurrentService] = useState(services[0]);

  return (
    <Container>
      <WrapperBody>
        <Fade bottom>
          <Title>{{ servicesSection }.servicesSection.title}</Title>
        </Fade>
        <ServicesWrapper>
          <Holder
            active={currentService.ref === "webAndDesktop" ? true : false}
          >
            <ServicesTitle
              active={currentService.ref === "webAndDesktop" ? true : false}
              onClick={() => {
                setCurrentService(services[0]);
              }}
            >
              <Fade left distance="60%">
                {services[0].title}
              </Fade>
            </ServicesTitle>
            <ServicesDescription
              active={currentService.ref === "webAndDesktop" ? true : false}
            >
              <Fade left distance="10%" spy={currentService.ref}>
                {currentService.description}
              </Fade>
            </ServicesDescription>
          </Holder>

          <Holder active={currentService.ref === "apps" ? true : false}>
            <ServicesTitle
              active={currentService.ref === "apps" ? true : false}
              onClick={() => {
                setCurrentService(services[1]);
              }}
            >
              <Fade bottom distance="60%">
                {services[1].title}
              </Fade>
            </ServicesTitle>
            <ServicesDescription
              active={currentService.ref === "apps" ? true : false}
            >
              <Fade left distance="10%" spy={currentService.ref}>
                {currentService.description}
              </Fade>
            </ServicesDescription>
          </Holder>

          <Holder active={currentService.ref === "outsourcing" ? true : false}>
            <ServicesTitle
              active={currentService.ref === "outsourcing" ? true : false}
              onClick={() => {
                setCurrentService(services[2]);
              }}
            >
              <Fade right distance="60%">
                {services[2].title}
              </Fade>
            </ServicesTitle>
            <ServicesDescription
              active={currentService.ref === "outsourcing" ? true : false}
            >
              <Fade left distance="10%" spy={currentService.ref}>
                {currentService.description}
              </Fade>
            </ServicesDescription>
          </Holder>
        </ServicesWrapper>

        <ServicesWrapper>
          <Holder active={currentService.ref === "ecommerce" ? true : false}>
            <ServicesTitle
              active={currentService.ref === "ecommerce" ? true : false}
              onClick={() => {
                setCurrentService(services[3]);
              }}
            >
              <Fade right distance="60%">
                {services[3].title}
              </Fade>
            </ServicesTitle>
            <ServicesDescription
              active={currentService.ref === "ecommerce" ? true : false}
            >
              <Fade left distance="10%" spy={currentService.ref}>
                {currentService.description}
              </Fade>
            </ServicesDescription>
          </Holder>

          <Holder active={currentService.ref === "bankservice" ? true : false}>
            <ServicesTitle
              active={currentService.ref === "bankservice" ? true : false}
              onClick={() => {
                setCurrentService(services[4]);
              }}
            >
              <Fade right distance="60%">
                {services[4].title}
              </Fade>
            </ServicesTitle>
            <ServicesDescription
              active={currentService.ref === "bankservice" ? true : false}
            >
              <Fade left distance="10%" spy={currentService.ref}>
                {currentService.description}
              </Fade>
            </ServicesDescription>
          </Holder>

          <Holder active={currentService.ref === "squadservice" ? true : false}>
            <ServicesTitle
              active={currentService.ref === "squadservice" ? true : false}
              onClick={() => {
                setCurrentService(services[5]);
              }}
            >
              <Fade right distance="60%">
                {services[5].title}
              </Fade>
            </ServicesTitle>
            <ServicesDescription
              active={currentService.ref === "squadservice" ? true : false}
            >
              <Fade left distance="10%" spy={currentService.ref}>
                {currentService.description}
              </Fade>
            </ServicesDescription>
          </Holder>
        </ServicesWrapper>
      </WrapperBody>
    </Container>
  );
};

export default OurServices;
