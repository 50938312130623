export const langEN = {
  navigation: {
    blog: "Blog",
    joinUs: "Join Us",
    contactUs: "Contact Us",
  },

  homeSection: {
    title: "Development of personalized digital products.",
    subtitle:
      "We are specialists in adding value to your company through solutions that align people, processes and systems.",
    partnersTitle: "Growing with us:",
  },

  servicesSection: {
    title: "What we deliver with excellence:",
    services: [
      {
        title: "Design & Marketing",
        description:
          "No dawdling. We combine the best design methodologies with your brand to deliver solutions that will drive your business. With good management and marketing  consultancy we optimize your company's results!",
      },
      {
        title: "Web & Apps",
        description:
          "At Arkos we create apps that make all the difference! Android, Desktop or IOS , our goal is to bring value to your business from the perspective of customization, design and user experience excellence. We offer: institucional website, one page, blog, design, landing page development and web creation in general.",
      },
      {
        title: "Automation",
        description:
          "Now you can eliminate most of the manual process in your company! Softwares that automates repetitive activities can save you time and money, allowing your employees to perform higher-value activities. We offer RPA (Robotic Process Automation), Chatbot, middleware and others.",
      },
      {
        title: "E-commerce",
        description:
          "There are no limits for those who have a virtual shop. Available to sell 24/7, high stock control, no physical limitation, relatively low maintenance and development cost. At Arkos we have the best solution for building, designing and integrating with other platforms or boosting your e-commerce. In addition to maximum customization according to: delivery system, multiple payment methods, integrated control dashboards.",
      },
      {
        title: "Bank as a Service",
        description:
          "Have you ever thought about the possibility of managing your own financial solutions in a simple and secure way?  With our white label platform , transform your company in a banking service provider with innovative and tailored products to your public necessities. We are ready to work with any kind of segment or business model, from a startup to large retailers.",
      },
      {
        title: "Squad as a Service",
        description:
          "Receive our front-end, back-end, design and framework professionals at your company as part of your team.",
      },
    ],
  },

  testimonials: [
    {
      authorOccupation: "CEO at Urbis",
      content:
        "The Arkos team understood the needs of my business, proposed assertive solutions and executed them all with great professionalism. With them, we are safer in technology and we can evolve the business, Arkos bought the idea of Urbis together with me.",
    },
  ],

  contactSection: {
    contactTitle: "Contacts",
    adressSubSection: {
      title: "We are at",
      adressTitle: "Brazil, Forlateza - CE",
      adress: "Etevaldo Nogueira Business",
      adressCep: "Av. Dom Luis, 870, 20º andar - 60160-230",
      adressTwoTitle: "Brazil, São Paulo - SP",
      adressTwo: "You Metropolitan Tatuape",
      adressTwoCep: "Rua Catiguá, 159, 19º andar - 03065-030",
    },
    form: {
      title: "Lets talk?",
      interest: "",
      service: [
        {
          title: "Development",
        },
        {
          title: "Outsourcing",
        },
      ],
      investment: "and i'm willing to invest",
      budget: [
        {
          value: "less than R$ 5.000",
        },
        {
          value: "between R$ 5.000 and R$ 10.000",
        },
        {
          value: "between R$ 10.000 and R$ 20.000",
        },
        {
          value: "more than R$ 20.000",
        },
        {
          value: "still don't know",
        },
      ],
      namePlaceholder: "Name",
      emailPlaceholder: "Mail",
      phonePlaceholder: "Phone",
      submitButton: "Submit",
      errors: {
        service: "Tell us which service you want",
        budget: " Enter your budget",
        name: "Your name is required",
        email: "Your mail is required",
        invalidEmail: "Invalid email!",
        phone: "Your phone is required",
        generalError: "The form has errors, correct them to continue",
      },
      loading: {
        title: "Sending to our team",
        subtitle: "Please wait",
      },
      send: {
        title: "Soon we will contact you",
        subtitle: "Arkos team thanks!",
      },
    },
    copyright: "All rights reserved",
  },
};
