import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { StyledInput, StyledInputMask } from './styles';

const Input = ({
  name,
  icon: Icon,
  withMask = false,
  mask,
  maskChar = ' ',
  ...rest
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      {withMask ? (
        <StyledInputMask
          alwaysShowMask={false}
          maskChar={maskChar}
          mask={mask}
          defaultValue={defaultValue}
          name={fieldName}
          ref={inputRef}
          {...rest}
        />
      ) : (
        <StyledInput
          defaultValue={defaultValue}
          name={fieldName}
          ref={inputRef}
          {...rest}
        />
      )}

      {error && (
        <p style={{ fontSize: '14px', color: '#f10' }}>
          {Icon && <Icon size={16} />}
          {'        '}
          {error}
        </p>
      )}
    </>
  );
};

export default Input;
