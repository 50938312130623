import React, { useState, useEffect } from "react";
import { VscChromeClose } from "react-icons/vsc";
import {
  StyledAlert,
  Strong,
  LinkToEnglish,
  Content,
  Message,
  Block,
} from "./styles";
import { langEN } from "@lang/langEN";

const AlertLanguage = ({ currentLang }) => {
  const [isCloseable, setClose] = useState(null);
  const [isEnglish, setEnglish] = useState(null);

  const handleLocalLang = () => {
    localStorage.setItem("langEnglish", true);
    setEnglish(localStorage.getItem("langEnglish"));
  };

  const handleClose = () => {
    localStorage.setItem("closeAlertLanguage", true);
    setClose(localStorage.getItem("closeAlertLanguage"));
  };

  useEffect(() => {
    if (window !== "undefined") {
      setClose(localStorage.getItem("closeAlertLanguage"));
      setEnglish(localStorage.getItem("langEnglish"));
    }
  }, []);

  return (
    <>
      {!isCloseable && !isEnglish && currentLang !== langEN && (
        <StyledAlert>
          <Content>
            <Message>
              Do you want to view the site in <Strong>English</Strong>?
            </Message>
            <Block>
              <LinkToEnglish href="/en" onClick={handleLocalLang}>
                Continue
              </LinkToEnglish>

              <VscChromeClose
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </Block>
          </Content>
        </StyledAlert>
      )}
    </>
  );
};

export default AlertLanguage;
