import styled from "styled-components";

export const NavWrapper = styled.div`
  width: 100%;
  position: fixed;
  height: 72px;
  z-index: 2;
  left: 0;
  top: -1px;

  .scrollPage {
    opacity: 1;
    display: flex;
    justify-content: center;
    background: ${(props) => props.theme.background};
    transition: all 0.7s ease-in-out;
    transform: translateY(0px);
    box-shadow: 0px 0px 10px -3px ${(props) => props.theme.primary};
  }

  .scrollPageInitial {
    opacity: 0;
    width: 100%;
    transition: all 0.7s ease-in-out;
    transform: translateY(-100%);
  }
`;

export const NavbarHolder = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  height: 72px;
  padding: 16px 32px;
  z-index: 2;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;

  color: ${(props) => props.theme.background};
`;

export const Logo = styled.img`
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-right: ${({ fadeLogo }) => (fadeLogo ? "auto" : "unset")};
`;

export const LogoHolder = styled.div`
  display: flex;
`;

export const NavList = styled.div`
  display: flex;
  margin-left: auto;
`;

export const Link = styled.a`
  font-weight: normal;
  font-size: 16px;
  color: ${(props) => props.theme.text};
  margin: 0 20px;
  position: relative;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    height: 2px;
    bottom: -3px;
    left: 0;
    visibility: hidden;
    width: 0;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &:before {
      background-color: ${(props) => props.theme.primary};
      visibility: ${({ withoutBottom }) =>
        withoutBottom ? "hidden" : "visible"};
      width: 100%;
    }
  }

  @media screen and (max-width: 324px) {
    font-size: 14px;

    &:last-child {
      margin: 0;
    }
  }
`;
