import styled from "styled-components";
import { Container as StyledContainer } from "@components/Container";
import { Row as StyledRow } from "@components/Row";
import { Col as StyledCol } from "@components/Col";
import { Form as StyledForm } from "@unform/web";
import { shade, transparentize } from "polished";

export const Container = styled(StyledContainer)`
  min-height: 688px;
  color: ${(props) => props.theme.text};
  height: auto;
  padding: 108px 24px;
  background: ${(props) => props.theme.lightHolder};
  max-width: 100%;
`;

export const Row = styled(StyledRow)`
  width: 100%;
`;

export const Col = styled(StyledCol)`
  margin-bottom: 16px;

  &:not(:last-child) {
    margin-right: 24px;
  }

  @media screen and (max-width: 1033px) {
    width: 100%;
    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const ContactWrapper = styled.div`
  max-width: 1180px;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 auto;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 464px;
  padding-right: 64px;

  @media screen and (max-width: 992px) {
    padding: 16px 0 0;
    width: 464px;
    max-width: 100%;
  }
`;

export const Right = styled.div`
  padding-left: 64px;
  display: flex;
  flex-direction: column;
  width: 564px;
  min-height: 503px;
  border-left: 1px solid ${(props) => transparentize(0.8, props.theme.text)};

  @media screen and (max-width: 992px) {
    border: 0;
    width: 464px;
    max-width: 100%;
    padding: 32px 0 0;
  }
`;

export const MediumTitle = styled.span`
  max-width: 256px;
  font-weight: bold;
  /* padding: ${(props) => (props.padding ? props.padding : "0")}; */
  padding: 42px 0 5px;
  font-size: 23px;
`;

export const Text = styled.span`
  max-width: 256px;
  margin: 8px 0;
  line-height: 150%;
`;

export const MediumText = styled(Text)`
  position: relative;
  font-weight: normal;
  cursor: pointer;

  h3:nth-child(1n) {
    padding-top: 10px;
  }

  &:before {
    content: "";
    position: absolute;
    height: 2px;
    bottom: -3px;
    left: 0;
    visibility: hidden;
    width: 0;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    &:before {
      background-color: ${(props) => props.theme.primary};
      visibility: visible;
      width: 100%;
    }
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.text};
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.5s;
  max-width: ${({ adress }) => (adress ? "312px" : "unset")};

  &:first-child {
    padding-left: 0;
    margin-left: 0;
  }

  &:last-child {
    padding-right: 0;
    margin-right: 0;
  }

  &:nth-child(7n) {
    padding-top: 10px;
  }

  @media screen and (max-width: 380px) {
    &:first-child {
      margin: 12px 0;
    }

    &:last-child {
      margin: 12px 0;
    }
  }
`;

export const ErrorTag = styled.p`
  font-size: 14px;
  color: #f10;
`;

export const BigText = styled.span`
  font-family: Orkney;
  font-size: ${(props) => (props.typeTitle ? "32px" : props.fontSize)};
  font-weight: ${(props) => (props.typeTitle ? "800" : "400")};
  padding: ${(props) => (props.padding ? props.padding : "12px 0 0 0")};
`;

export const BigTitle = styled.h1`
  font-size: 24px;
  font-family: Orkney;
`;

export const IconUl = styled.ul`
  display: flex;
  padding-top: 48px;

  @media screen and (max-width: 380px) {
    flex-wrap: wrap;
  }
`;

export const IconList = styled.li`
  padding: 12px 24px 0 0;
  margin: 8px 16px 0 0;
  transition: all 0.3s;
  color: ${(props) => props.theme.text};

  &:hover {
    transform: translateY(-4px);

    svg {
      path {
        fill: ${(props) => props.theme.primary};
      }
    }
  }
`;

export const Form = styled(StyledForm)`
  display: flex;
  flex-direction: column;
`;

export const TagTitle = styled.span`
  font-size: 18px;
  line-height: 150%;
  padding: 32px 0 8px;
`;

export const TagUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const TagList = styled.li`
  @media screen and (max-width: 374px) {
    width: 100%;
  }
`;

export const Tag = styled.button`
  padding: 4px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  border-radius: 4px;
  color: ${(props) =>
    props.active ? props.theme.background : props.theme.text};
  background: ${(props) =>
    props.active ? props.theme.primary : "rgba(202, 205, 209, 0.25)"};
  transition: all 0.2s;
  margin-bottom: 16px;

  &:hover {
    color: ${(props) => props.theme.background};
    background: ${(props) => shade(0.4, props.theme.primary)};
  }

  &:focus {
    color: ${(props) => props.theme.background};
    background: ${(props) => props.theme.primary};
  }

  &:not(:last-child) {
    margin-right: 16px;
  }

  @media screen and (max-width: 374px) {
    &:not(:last-child) {
      margin-right: 0px;
    }
    min-height: 50px;
    width: 100% !important;
  }
`;

export const SubmitButton = styled.button`
  display: block;
  padding: 8px 68px;
  background: ${(props) =>
    props.disabled ? shade(0.2, "#f1f1f1") : "transparent"};
  border: 1px solid
    ${(props) => (props.disabled ? shade(0.2, "#f1f1f1") : props.theme.primary)};
  color: ${(props) =>
    props.disabled
      ? transparentize(0.5, props.theme.text)
      : props.theme.primary};
  margin: 16px 0;
  margin-left: auto;
  border-radius: 4px;
  transition: all 0.3s;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background: ${(props) =>
      props.disabled ? shade(0.2, "#f1f1f1") : props.theme.primary};
    color: ${(props) =>
      props.disabled
        ? transparentize(0.8, props.theme.text)
        : props.theme.background};
    transform: ${(props) => (props.disabled ? "none" : "translateY(-4px)")};
  }

  @media screen and (max-width: 1033px) {
    margin-left: 0;
    margin-right: auto;
  }

  @media screen and (max-width: 364px) {
    margin: 0 auto;
  }
`;

export const BlockCenter = styled.div`
  display: block;
  margin: auto;
`;

export const SendImage = styled.img`
  width: 280px;
  height: 180px;
`;

export const Label = styled.label`
  font-size: 0;

  @media screen and (max-width: 1033px) {
    input {
      width: 100%;
    }
  }
`;
