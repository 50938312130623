import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    scroll-behavior: smooth !important;
    font-display: fallback;
  }

  body, input, button {
    font: 16px Raleway, sans-serif;
  }

  @font-face {
  font-display: fallback;
  font-family: 'Raleway';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/raleway-regular.woff');
  }

  @font-face {
  font-display: fallback;
  font-family: 'Raleway';
  font-weight: bold;
  font-style: normal;
  src: url('/fonts/raleway-bold.woff');
  }

  @font-face {
  font-display: fallback;
  font-family: 'Orkney';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/orkney-light.woff');
  }

  @font-face {
    font-family: 'Orkney';
    font-display: fallback;
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/orkney-regular.woff');
  }

  @font-face {
    font-display: fallback;
    font-family: 'Orkney';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/orkney-medium.woff');
  }

  @font-face {
    font-display: fallback;
    font-weight: bold;
    font-style: normal;
    font-family: 'Orkney';
    src: url('/fonts/orkney-bold.woff');
  }

  button {
    cursor: pointer;
  }

  a {
      text-decoration: none;
  }

  li {
    list-style: none;
  }

  body {
    margin: 0 auto;
  }
`;
