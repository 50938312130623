import React from 'react';

import Navbar from './Navbar';
import Home from './Home';
import Slider from './Slider';
import OurServices from './OurServices';
import SliderTestimonials from './SliderTestimonials';
import Contact from './Contact';
import Footer from './Footer';

import AlertLanguage from '@components/AlertLanguage';

import LanguageContext from '@lang/LanguageContext';

const Development = () => {
  return (
    <LanguageContext.Consumer>
      {(currentLang) => (
        <>
          <AlertLanguage currentLang={currentLang} />
          <Navbar />
          <Home />
          <Slider />
          <OurServices />
          <SliderTestimonials />
          <Contact />
          <Footer />
        </>
      )}
    </LanguageContext.Consumer>
  );
};

export default Development;
