import styled from "styled-components";
import { Container as StyledContainer } from "@components/Container";

export const Container = styled(StyledContainer)`
  max-width: 100%;
  padding-top: 66px;
  background: ${(props) => props.theme.primary};

  @media (min-width: 768px) {
    padding-top: 72px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  max-width: 1180px;

  .gatsby-image-wrapper {
    width: 300px;
  }
`;

export const Title = styled.div`
  color: ${(props) => props.theme.background};
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  font-family: Orkney;
  max-width: 500px;

  @media (min-width: 768px) {
    max-width: 870px;
    font-size: 60px;
    line-height: 72px;
  }
`;

export const Subtitle = styled.div`
  color: ${(props) => props.theme.background};
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  padding: 16px 0 24px 0;
  max-width: 400px;

  @media (min-width: 888px) {
    font-size: 18px;
    max-width: 615px;
  }
`;

export const SliderTitle = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.background};
  font-size: 24px;
  font-weight: 500;
  max-width: 400px;
  text-align: center;
  padding-top: 24px;

  @media (min-width: 768px) {
    max-width: 574px;
    font-size: 40px;
  }
`;
