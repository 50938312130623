import React from 'react';
import { FaLinkedinIn } from 'react-icons/fa';
import { ImWhatsapp } from 'react-icons/im';
import { SiInstagram, SiGithub } from 'react-icons/si';

export const iconsList = [
  {
    label: "Whatsapp",
    href:
      'https://api.whatsapp.com/send?phone=558532280166&text=Ol%C3%A1,%20cheguei%20aqui%20atrav%C3%A9s%20do%20site.%20Poderia%20me%20ajudar?',
    icon: <ImWhatsapp size={32} />,
  },
  {
    label: "Instagram",
    href: 'https://www.instagram.com/arkos.digital/',
    icon: <SiInstagram size={32} />,
  },
  {
    label: "Linkedin",
    href: 'https://www.linkedin.com/company/arkosdigital',
    icon: <FaLinkedinIn size={32} />,
  },
  {
    label: "Github",
    href: 'https://github.com/ArkosDigital',
    icon: <SiGithub size={32} />,
  },
];
